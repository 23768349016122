import { Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';

import { CookieService } from '../core/cookie/cookie.service';
import { Merchant } from './merchant.enum';

@Component({
  selector: 'kyc-theme',
  templateUrl: 'theme.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ThemeComponent implements OnInit {

  @ViewChild('themeContainer', { read: ViewContainerRef })
  themeContainer: ViewContainerRef;

  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const merchant = this.authService.getMerchantName()?.toUpperCase()
      || this.cookieService.get('merchantName')?.toUpperCase();

    this.lazyLoadTheme(merchant as Merchant);
  }

  private lazyLoadTheme(merchant: Merchant): void {
    switch (merchant) {
      case Merchant.SKRILL:
        import('./skrill/skrill-theme.component').then(({ SkrillThemeComponent }) =>
          this.lazyLoadComponent(SkrillThemeComponent)
        );
        break;
      case Merchant.NETELLER:
        import('./neteller/neteller-theme.component').then(({ NetellerThemeComponent }) =>
          this.lazyLoadComponent(NetellerThemeComponent)
        );
        break;
      case Merchant.XSOLLA:
      case Merchant.LIBERTY:
      case Merchant.SWORDPAY:
        import('./xsolla/xsolla-theme.component').then(({ XsollaThemeComponent }) =>
          this.lazyLoadComponent(XsollaThemeComponent)
        );
        break;
      default:
        // TODO: create default theme
        throw new Error(`Unsupported merchant: ${merchant}`);
    }
  }

  private lazyLoadComponent(component: any): void {
    this.themeContainer.createComponent(component);
  }
}
