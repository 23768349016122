import { Injectable } from '@angular/core';

import { Spinner } from './spinner.interface';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService implements Spinner {

  start(): void { }

  stop(): void { }
}
